<template>
	<div>
		<title-bar :title-stack="titleStack"/>

		<section class="section is-main-section">
			<card-component class="has-table has-mobile-sort-spaced" :hasButtonSlot="true" title="一覧" icon="format-list-bulleted">
				<b-button slot="button" type="is-success is-small" @click="$router.push({ name: 'account-new' })">新規作成</b-button>

				<b-table
					:loading="isLoading"
					:paginated="true"
					:per-page="$consts.TABLE_PER_PAGE"
					:striped="true"
					:hoverable="true"
					default-sort="name"
					:data="list"
					@page-change="onPageChange"
					>

					<b-table-column label="名前" field="name" sortable v-slot="props">
						{{ props.row.name }}
					</b-table-column>
					<b-table-column label="フリガナ" field="name_kana" sortable v-slot="props">
						{{ props.row.name_kana }}
					</b-table-column>
					<b-table-column label="メールアドレス" field="email" sortable v-slot="props">
						{{ props.row.email }}
					</b-table-column>
					<b-table-column label="権限" field="permission" sortable v-slot="props">
						{{ props.row.permission }}
					</b-table-column>

					<b-table-column label=" " v-slot="props">
						<div class="buttons is-right">
							<button class="button is-small is-warning" type="button" @click="$router.push({ name: 'account-edit', params: { accountId: props.row.id }})">編集</button>
							<button class="button is-small is-danger" :style="{ 'visibility': deleteButtonVisibility }" type="button" @click="onDeleteClick(props.row.id)">削除</button>
						</div>
					</b-table-column>

					<section class="section" slot="empty">
						<table-empty :loading="isLoading"></table-empty>
					</section>

					<section class="page-info" slot="bottom-left">
						<page-info :current="currentPage" :data="list"></page-info>
					</section>
				</b-table>
			</card-component>
		</section>
	</div>

</template>

<script>
import Notification from '@/components/Notification'
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import RefreshButton from '@/components/RefreshButton'
import CardToolbar from '@/components/CardToolbar'
import TableEmpty from '@/components/TableEmpty'
import PageInfo from '@/components/PageInfo'

export default {
	name: 'Account',
	components: {
		TitleBar,
		CardComponent,
		TableEmpty,
		PageInfo,
	},
	data () {
		return {
			isLoading: false,
			list: [],
			currentPage: 1,
		}
	},
	computed: {
		titleStack () {
			return [
				this.$route.meta.title,
			]
		},
		deleteButtonVisibility: function() {
			return this.list.length > 1 ? 'visible' : 'hidden';
		},
	},
	mounted () {
		this.init();
	},
	methods: {
		init: function() {
			this.getData();
		},
		getData: async function() {
			this.isLoading = true;
			let ep = 'administrators';
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.list = res.data.list;
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isLoading = false;
			}
		},
		onDeleteClick: async function(id) {
			this.$utils.log(id);
			if (!window.confirm(this.$consts.MSG_CONFIRM_DELETE)) return;

			this.isLoading = true;
			let ep = 'administrators/' + id;
			this.$utils.log(ep);

			try {
				let res = await this.$api.delete(ep, null, true);
				this.$utils.log(res);
				
				this.list.some(function(value, index) {
					if (value.id === id) this.list.splice(index, 1);
				}, this);

				this.$utils.toastSuccess('削除しました');
				this.getData();
			} catch (error) {
				this.$utils.log(error);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isLoading = false;
			}
		},
		onPageChange: function(val) {
			this.currentPage = val;
		},
	},
}
</script>
