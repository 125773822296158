<template>
	<div>
		<title-bar :title-stack="titleStack"/>

		<section class="section is-main-section">
			<card-component title="基本情報" icon="">
				<b-field label="名前"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'name') }"
					:message="errors.name">
					<b-input type="text" v-model="name" />
				</b-field>

				<b-field label="名前(フリガナ)"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'nameKana') }"
					:message="errors.nameKana">
					<b-input type="text" v-model="nameKana" />
				</b-field>

				<b-field label="メールアドレス"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'email') }"
					:message="errors.email">
					<b-input type="email" v-model="email" />
				</b-field>

				<b-field label="パスワード"
					v-if="!id"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'password') }"
					:message="errors.password">
					<b-input type="password" v-model="password" placeholder="半角英数4文字以上" password-reveal />
				</b-field>

				<b-field label="権限"
					horizontal
					custom-class="require"
					>
					<radio-picker :options="adminPermissions" v-model="adminPermissionId"></radio-picker>
				</b-field>

				<b-field horizontal class="form-buttons">
					<b-field grouped>
						<div class="control">
							<b-button @click="$router.go(-1)">戻る</b-button>
						</div>
						<div class="control">
							<b-button type="is-primary" :loading="isLoading" @click="onSubmitClick">{{ !id ? '登録' : '更新' }}</b-button>
						</div>
					</b-field>
				</b-field>
			</card-component>

			<card-component v-if="id" title="パスワード変更" icon="">
				<b-field label="パスワード"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'password') }"
					:message="errors.password">
					<b-input type="password" v-model="password" placeholder="半角英数4文字以上" password-reveal />
				</b-field>

				<b-field class="form-buttons" horizontal>
					<div>
						<b-button type="is-primary" class="" :loading="isPasswordLoading" @click="onPasswordChangeClick">変更する</b-button>
					</div>
				</b-field>
			</card-component>
		</section>
	</div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import RadioPicker from '@/components/RadioPicker'

export default {
	name: 'AccountEdit',
	components: {
		RadioPicker,
		CardComponent,
		TitleBar,
	},
	data () {
		return {
			id: '',
			email: '',
			password: '',
			name: '',
			nameKana: '',
			adminPermissionId: null,
			errors: {
				name: '',
				nameKana: '',
				email: '',
				password: '',
			},
			adminPermissions: null,
			isLoading: false,
			isPasswordLoading: false,
		}
	},
	computed: {
		titleStack () {
			return [
				this.$route.meta.title,
			]
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		init: function() {
			this.getData();
		},
		getData: async function() {
			let loader = this.$buefy.loading.open();
			let params = this.$route.params.accountId ? '/' + this.$route.params.accountId : '';
			let ep = 'administrators/screen' + params;
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.id = res.data.id;
				this.name = res.data.name;
				this.nameKana = res.data.name_kana;
				this.email = res.data.email;
				this.adminPermissionId = res.data.admin_permission_id;
				this.adminPermissions = res.data.admin_permissions;
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				loader.close();
			}
		},
		onSubmitClick: async function() {
			for (let key in this.errors) {
				this.errors[key] = '';
			}

			this.isLoading = true;
			let data = {
				"id": this.id,
				"name": this.name,
				"name_kana": this.nameKana,
				"email": this.email,
				"password": this.password,
				"admin_permission_id": this.adminPermissionId,
			};

			this.$utils.log(data);

			try {
				let ep = 'administrators';

				if (!this.id) {
					await this.$api.post(ep, data, true);
				} else {
					await this.$api.put(ep + '/' + this.id, data, true);
					this.$utils.toastSuccess('データを更新しました');
				}
				this.$router.push({ name: 'account' });
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
				if (error.response.status === 400) {
					let details = error.response.data.details;
					for (let key in details) {
						this.errors[this.$utils.toCamelCase(key)] = details[key];
					}
				}
			} finally {
				this.isLoading = false;
			}
		},
		onPasswordChangeClick: async function() {
			for (let key in this.errors) {
				this.errors[key] = '';
			}

			this.isPasswordLoading = true;
			let data = {
				"id": this.id,
				"password": this.password,
			};
			this.$utils.log(data);

			let ep = 'administrators/password';
			this.$utils.log(ep);
			
			try {
				let res = await this.$api.post(ep, data, true);
				this.$utils.log(res);
				this.$utils.toastSuccess('パスワードを変更しました');
				this.$router.push({ name: 'account' });
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
				if (error.response.status === 400) {
					let details = error.response.data.details;
					for (let key in details) {
						this.errors[this.$utils.toCamelCase(key)] = details[key];
					}
				}
			} finally {
				this.isPasswordLoading = false;
			}
		},
	}
}
</script>
